import React from "react"

import Layout from "components/layout"
import SEO from "components/seo"
import Container from "components/Container"
import Image from "gatsby-image/withIEPolyfill"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import styled from "styled-components"

const StyledImageWrapper = styled.div`
  height: 150px; // Ensure width and height are the same for a perfect circle
  width: 150px; // Same as height
  overflow: hidden; // Ensures no part of the image spills outside the circle
  border-radius: 50%; // Makes the wrapper circular
  display: flex;
  justify-content: center;
  align-items: center;

  .img-wrapper {
    height: 100%; // Full height of the parent
    width: 100%; // Full width of the parent
    object-fit: cover; // Ensures the image covers the space without distorting aspect ratio
  }
`

const About = ({ data }) => (
  <Layout>
    <SEO title="About" />
    <Container title="About Us">
      <p>
        {`We are a California based team that can only differentiate themselves from the thousands of agencies in one simple way: we partner with our clients and treat them as equals, not just a dollar sign. We do what we say, and we want to deliver meaningful software that improves small and medium sized businesses.`}
      </p>
    </Container>

    <Container title="The Team">
      <div className="about">
        <a
          className="badge-base__link LI-simple-link"
          href="https://www.linkedin.com/in/babakchehraz?trk=profile-badge"
        >
          <div className="teamBadge">
            <div className="teamMember">
              <StyledImageWrapper>
                <Image
                  className="img-wrapper"
                  fluid={
                    data.allFile.nodes.find(node => node.name === "bc")
                      ?.childImageSharp.fluid
                  }
                />
              </StyledImageWrapper>
            </div>
            <div>Babak Chehraz</div>
            <div>Senior Software Developer</div>
          </div>
        </a>
        <a
          className="badge-base__link LI-simple-link"
          href="https://www.linkedin.com/in/brycejjacks?trk=profile-badge"
        >
          <div className="teamBadge">
            <div className="teamMember">
              <StyledImageWrapper>
                <Image
                  className="img-wrapper"
                  fluid={
                    data.allFile.nodes.find(node => node.name === "bj")
                      ?.childImageSharp.fluid
                  }
                />
              </StyledImageWrapper>
            </div>
            <div>Bryce Jackson</div>
            <div>Software Developer</div>
          </div>
        </a>
        <a
          className="badge-base__link LI-simple-link"
          href="https://www.linkedin.com/in/howard-luong?trk=profile-badge"
        >
          <div className="teamBadge">
            <div className="teamMember">
              <StyledImageWrapper>
                <Image
                  className="img-wrapper"
                  fluid={
                    data.allFile.nodes.find(node => node.name === "hl")
                      ?.childImageSharp.fluid
                  }
                />
              </StyledImageWrapper>
            </div>
            <div>Howard Luong</div>
            <div>Owner, Software Developer</div>
          </div>
        </a>
      </div>
    </Container>
  </Layout>
)

About.propTypes = {
  data: PropTypes.object.isRequired,
}

export default About

export const query = graphql`
  query {
    allFile(filter: { sourceInstanceName: { eq: "about-images" } }) {
      nodes {
        name
        childImageSharp {
          fluid(maxWidth: 550) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`
